import { useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Project from "../../../../api/Admin/Project";
import { sortAscending } from "../ProjectManagement/helpers";
import { CustomNetworkError } from "../../../../api/helpers";
import { DataTableWrapper } from "../ProjectManagement/components/DataTableWrapper";
import { StyledTableCell, StyledTableRow } from "../../../../components/UXDataTable";
import { CustomMenu } from "../ProjectManagement/components/CustomMenu";
import CustomModal from "../../../../components/Modal";
import { ProjectApprovalForm } from "./ProjectApprovalForm"
import { Alert } from "../../../Admin/General/ProjectManagement/components/Alert";

type ProjectManagementAlert = {
    title: string;
    message: string;
    details?: string | undefined;
    onOk?: () => void;
};

const ProjectApproval = () =>
{
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userIsUnauthorized, setUserIsUnauthorized] = useState<boolean>(true);
    const [alert, setAlert] = useState<ProjectManagementAlert | undefined>(undefined);
    const [showProjectApprovalFormModal, setShowProjectApprovalFormModal] = useState(false);
    const [project, setProject] = useState<Project | undefined>();
    const [triggerEffect, setTriggerEffect] = useState(0);

    const tableKey = "projectsTable";

    const columns =
    [
        {
            id: "name",
            label: "Project Name",
            notSorteable: false
        },
        {
            id: "description",
            label: "Description",
            notSorteable: false
        },
        {
            id: "ownerEmail",
            label: "Project Owner",
            notSorteable: false
        },
        {
            id: "secondaryOwnerEmail",
            label: "Secondary Owner",
            notSorteable: false
        },
        {
            id: "reviewStatus",
            label: "Review Status",
            notSorteable: false
        },
    ];

    useEffect(() =>
    {
        setLoading(true);
        Project.getAllManagementProjects(["Pending"])
        .then((response: Project[]) =>
        {
            setUserIsUnauthorized(false);
            setProjects(response.sort((a, b) => sortAscending(a.name, b.name)));
        })
        .catch((err) =>
        {
            setAlert(errorFetchingProjectsAlert(getNetworkErrorMessage(err)));
        })
        .finally(() =>
        {
            setLoading(false);
        });
    }, [triggerEffect]);

    const handleApprovalClick = (project: Project) => {    
        setProject(project);
        setShowProjectApprovalFormModal(true);
    };

    const handleProjectFormOnClose = () => {
        setProject(undefined);
        setShowProjectApprovalFormModal(false);
        setTriggerEffect((prev) => prev + 1);
    };

    const handleAlertOkClick = () => {
        alert?.onOk?.();
        setAlert(undefined);
    };

    return (
    <>
        <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingBottom={".8rem"}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Header>All Projects ({projects.length})</Header>
            </Box>
        </Box>
        <DataTableWrapper
            tableId="projects"
            columns={columns}
            tableWidth="100%"
            blankFirstHeader={true}
            noDataFoundMessage={
                userIsUnauthorized
                    ? "You are not authorized to view or modify projects."
                    : "No projects available."
            }
            isDataLoading={loading}
            rowData={projects}
            rowComponent={(project, rowIndex) => (
            <StyledTableRow key={`${tableKey}-row-${rowIndex}`}>
                <StyledTableCell width={5}>
                    <CustomMenu key={`${tableKey}basic-menu${rowIndex}`}>
                        <MenuItem onClick={() => handleApprovalClick(project)}>Review</MenuItem>
                    </CustomMenu>
                </StyledTableCell>
                <StyledTableCell>{project.name}</StyledTableCell>
                <StyledTableCell>{project.description}</StyledTableCell>
                <StyledTableCell>{project.ownerEmail}</StyledTableCell>
                <StyledTableCell>{project.secondaryOwnerEmail}</StyledTableCell>
                <StyledTableCell>{project.reviewStatus}</StyledTableCell>
            </StyledTableRow>
        )}
    />
    <CustomModal
        title="Approve / Reject Project"
        open={showProjectApprovalFormModal}
        setOpen={setShowProjectApprovalFormModal}
        setClose={undefined}>
        <ProjectApprovalForm
            project={project}
            onCancel={handleProjectFormOnClose}
        />
    </CustomModal>
    <Alert
        title={alert?.title}
        message={alert?.message}
        details={alert?.details}
        display={alert !== undefined}
        onOk={handleAlertOkClick}
    />
    </>);
};


const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403) {
        return "You are not authorized to view or modify projects.";
    }
    return (
        error.message ??
        "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
};

const errorFetchingProjectsAlert = (details: string): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error fetching projects.",
  details: `Reason: ${details}`,
});

const Header = styled(Box)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  paddingRight: 10,
}));

export default ProjectApproval;
