import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Menu,
  MenuItem,
  TableRow,
  TextField
} from '@mui/material';
import MoreOptions from "@mui/icons-material/MoreVert";
import { useEffect, useState } from 'react';
import SDSRequestType from '../../../../api/SDSRequest/SDSRequestType';
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from '../../../../components/UXDataTable';
import toast from 'react-hot-toast';

const SdsRequestTypeAdmin = () => {
  const [requestTypes, setRequestTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('typeName');
  const [showLoading, setShowLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    async function getData() {
      await fetchData();
    }
    getData();
  }, []);

  async function fetchData() {
    setShowLoading(true);
    const data = await SDSRequestType.getAll();
    setRequestTypes(data);
    setShowLoading(false);
  }

  const handleEdit = (type) => {
    setCurrentType(type);
    setIsEditing(true);
    setShowModal(true);
    handleCloseMoreOptions();
  };

  const handleAddNew = () => {
    setCurrentType(null);
    setIsEditing(false);
    setShowModal(true);
  };

  const handleSave = async (type) => {
    setShowLoading(true);
    try {
      if (isEditing) {
        await SDSRequestType.update(type);
      } else {
        await SDSRequestType.create(type);
      }
      setShowModal(false);
      await fetchData();
    } catch (error) {
      toast.error('Error saving request type');
    } finally {
      setShowLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setIsEditing(false);
  };

  const columns = [
    { id: "", label: "", notSortable: true },
    { id: "typeName", label: "Type Name" },
    { id: "description", label: "Description" },
    { id: "isActive", label: "Is Active" }
  ];

  function handleOpenMoreOptions(e, type) {
    setMoreOptionsSelected(type);
    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);
    setAnchorEl(null);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStartArray = () => {
    return stableSort(requestTypes, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Button variant="contained" onClick={handleAddNew} style={{ marginTop: "1rem", marginBottom: "1rem" }} >Add New Request Type</Button>
      <UXDataTableWithoutBody
        tableId={"sdsRequestTypeTable"}
        cols={columns}
        rowLength={requestTypes.length}
        enablePaging={true}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={showLoading ? 'Loading, please wait...' : 'No data found'}
        loading={showLoading}
      >
        <StyledTableBody>
          {determineStartArray().map((rowItem, index) => (
            <TableRow key={index}>
              <StyledTableCell component="th" scope="row" style={{ width: "10px" }}>
                <MoreOptions onClick={(e) => handleOpenMoreOptions(e, rowItem)} color="black" />
              </StyledTableCell>
              <StyledTableCell>{rowItem.typeName}</StyledTableCell>
              <StyledTableCell>{rowItem.description}</StyledTableCell>
              <StyledTableCell>{rowItem.isActive ? 'Yes' : 'No'}</StyledTableCell>
            </TableRow>
          ))}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl && !!moreOptionsSelected}
        onClose={handleCloseMoreOptions}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem
          key="mnuEdit"
          onClick={() => handleEdit(moreOptionsSelected)}
        >
          Edit
        </MenuItem>
      </Menu>

      <Dialog open={showModal} onClose={handleClose}>
        <DialogTitle>{isEditing ? 'Edit Request Type' : 'Add New Request Type'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Type Name"
            type="text"
            fullWidth
            value={currentType?.typeName || ''}
            onChange={(e) => setCurrentType({ ...currentType, typeName: e.target.value })}
            disabled={isEditing}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={currentType?.description || ''}
            onChange={(e) => setCurrentType({ ...currentType, description: e.target.value })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentType?.isActive || false}
                onChange={(e) => setCurrentType({ ...currentType, isActive: e.target.checked })}
              />
            }
            label="Is Active"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowModal(false)}
            color="primary"
            disabled={showLoading}
          >
            Cancel
          </Button>

          <Button
            onClick={() => handleSave(currentType)}
            color="primary"
            disabled={showLoading}
          >
            {showLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SdsRequestTypeAdmin;