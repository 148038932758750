import { useEffect, useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import "./styles.css";
import { Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { WorkRequestFormAutocomplete, WorkRequestFormInput } from "../WorkRequestFormInput";
import LocateContainer from "../../../Tests/NewTests/LocateContainer";
import SearchIcon from "@mui/icons-material/Search";
import { getConvertedCurrentAmount, getOriginalAmount } from "../../shared";
import { defaultShippingItem } from "../constants";

const defaultSampleInfo = {
    existingContainerID: null,
    requestedAmountUoM: null,
    requestedAmount: null,
};

export const labelProp = {
    existingContainerID: 'Container',
    requestedAmountUoM: 'UoM',
    requestedAmount: 'Amt. Requested',
}

const CreateUpdateContainers = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    unitOfMeasureLoading,
    unitOfMeasure,
    mySamplesInfo,
    setMySamplesInfo,
    myContainerToEdit,
    selectedSampleInfo,
    shippingRequest,
    setShippingRequest,
    setIsRequiredComment,
    isProcess,
}) => {
    const [myContainer, setMyContainer] = useState(myContainerToEdit ?? defaultSampleInfo);
    const [containers, setContainers] = useState([]);
    const [errors, setErrors] = useState({
        requestedAmount: myContainerToEdit ? false : null,
        requestedAmountUoM: myContainerToEdit ? false : null,
    });
    const [locateContainerIsOpen, setLocateContainerIsOpen] = useState(false);
    const [locateContainerMidas, setLocateContainerMidas] = useState("");
    const [locateContainerOnlySearch, setLocateContainerOnlySearch] = useState(false);
    const canEdit = true

    const commonProps = {
        setData: setMyContainer,
        data: myContainer,
        setErrors,
        errors,
        margin: 'none',
        styled: {
            marginRight: "unset",
            marginBottom: "unset",
        }
    }

    const hasError = () => Object.entries(errors).some(([key, value]) => value === null || value === true);

    function updateErrors() {
        setErrors((prevErrors) => {
            const updatedErrors = Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = prevErrors[key] === null ? true : prevErrors[key];
                return acc;
            }, {});

            return updatedErrors;
        });
    }
    const key = `${myContainer?.sample?.sampleName}${myContainer.existingContainerID}`;
    const keyEdit = myContainerToEdit ? `${myContainerToEdit?.sample?.sampleName}${myContainerToEdit.existingContainerID}` : key;
    const allMyContainers = myContainerToEdit ? [...selectedSampleInfo.containers.map(info => {
        if (`${selectedSampleInfo?.sample?.sampleName}${info.existingContainerID}` === keyEdit) return myContainer;
        return info;
    })] : [...selectedSampleInfo.containers.filter(f => `${selectedSampleInfo?.sample?.sampleName}${f.existingContainerID}` !== keyEdit), { ...myContainer }];
    const containersSameKey = allMyContainers.filter(f => `${selectedSampleInfo?.sample?.sampleName}${f.existingContainerID}` === key);
    const totalAmt = getConvertedCurrentAmount(myContainer, false, containersSameKey);

    useEffect(() => {
        if (selectedSampleInfo) {
            setMyContainer({
                ...myContainer,
                sample: selectedSampleInfo.sample
            });
            setContainers(checkDupeContainersShippingRequest(selectedSampleInfo.sample, selectedSampleInfo.sample?.containers) ?? [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSampleInfo])


    function handleLocateContainerOpen(open, midas, onlySearch = false) {
        setLocateContainerIsOpen(open)
        setLocateContainerMidas(midas)
        setLocateContainerOnlySearch(onlySearch)
    }

    function checkDupeContainersShippingRequest(sample, containers) {
        if (!containers) return;

        const testUsedContainers = allMyContainers
            .filter(
                (x) =>
                    x.sample &&
                    selectedSampleInfo.sample.sampleName === sample.sampleName &&
                    x.existingContainerID
            )
            .map(
                (x) =>
                    containers.find((c) => c.id === x.existingContainerID)?.containerNumber
            );

        return containers.filter(
            (x) => (!testUsedContainers.includes(x.containerNumber) && x.containerStatusName === 'Confirmed') || x.id === myContainer?.existingContainerID
        ).map(container => ({
            containerNumber: `${container.containerNumber}`,
            existingContainerID: container.id
        }));
    }

    function submit() {
        if (hasError()) {
            updateErrors();
            return;
        } else {
            if (myContainerToEdit?.id && isProcess) setIsRequiredComment(true);

            setMySamplesInfo(mySamplesInfo.map(sample => {
                let sampleToReturn = sample;
                if (sample?.sample?.sampleName === selectedSampleInfo?.sample?.sampleName) {
                    sampleToReturn = {
                        ...sample,
                        containers: allMyContainers
                    }
                }
                return sampleToReturn;
            }));
            if (myContainer.isUsedInShipping) {
                const shippingItem = shippingRequest?.shippingItems?.find(s => s.containerID === myContainer.existingContainerID && s.sampleName === selectedSampleInfo?.sample?.sampleName);
                let newShippingRequest = shippingRequest;
                
                const newShippingItem = {
                    ...defaultShippingItem,
                    sampleName: selectedSampleInfo?.sample?.sampleName,
                    sample: selectedSampleInfo?.sample,
                    containerID: myContainer.existingContainerID,
                    description: selectedSampleInfo?.sample?.description,
                    requestedAmountUoM: myContainer.requestedAmountUoM
                }

                if (shippingItem) {
                    newShippingRequest.shippingItems = newShippingRequest.shippingItems.map(item => {
                        if (shippingItem.containerID === item.containerID && shippingItem.sampleName === item.sampleName)
                            return newShippingItem;

                        return item;
                    })
                } else {
                    newShippingRequest.shippingItems.push(newShippingItem);
                }
                setShippingRequest(newShippingRequest);
            } else if (shippingRequest?.shippingItems?.some(s => selectedSampleInfo?.sample?.sampleName === s.sampleName && s.containerID === myContainer.existingContainerID)) {
                setShippingRequest({
                    ...shippingRequest,
                    shippingItems: shippingRequest.shippingItems.filter(f => !(selectedSampleInfo?.sample?.sampleName === f.sampleName && f.containerID === myContainer.existingContainerID))
                })
            }
            setIsModalClose();
        }
    }

    return <ModalTwoButtons
        title="Container"
        button1Text={myContainerToEdit ? "Update" : "Add"}
        button1Action={submit}
        isButton1Disabled={false}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <form className="form-work-request-create-update-containers">
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width="100%">
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    options={containers}
                    propertyLabel={'containerNumber'}
                    property={'existingContainerID'}
                    noOptionsText={"No Containers Found"}
                    label={labelProp['existingContainerID']}
                    disabled={myContainer.sample === null}
                    setData={(data) => {
                        let currentContainer = myContainer?.sample.containers.find(
                            (x) => x.id === data.existingContainerID
                        );
                        setMyContainer({
                            ...data,
                            requestedAmount: myContainer?.sample.requestedAmount,
                            requestedAmountUoM: myContainer?.sample.requestedAmountUoM ?? currentContainer?.containerSizeUoM,
                            unitOfMeasure: myContainer?.sample.unitOfMeasure ?? currentContainer?.uom,
                            existingContainer: currentContainer,
                        });

                        setErrors({
                            ...errors,
                            requestedAmountUoM: false,
                            requestedAmount: null
                        })
                    }}
                />
                {myContainer.sample !== null &&
                    myContainer.sample?.containers.length > 0 && (
                        <Box>
                            <IconButton
                                type="button"
                                aria-label="search"
                                onClick={() => {
                                    myContainer.sample !== null &&
                                        myContainer.sample?.containers.length > 0
                                        ? handleLocateContainerOpen(true, myContainer?.sample?.sampleName, (myContainer.sample === null || !canEdit))
                                        : handleLocateContainerOpen(false, '');
                                }}
                            >
                                <SearchIcon style={{ fill: "blue" }} />
                            </IconButton>
                        </Box>
                    )}
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width={"100%"}>
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    options={unitOfMeasure.map(uom => ({ ...uom, requestedAmountUoM: uom.uoMName }))}
                    unitOfMeasureLoading
                    noOptionsText={
                        unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                    }
                    property={'requestedAmountUoM'}
                    propertyLabel={'uoMName'}
                    label={labelProp['requestedAmountUoM']}
                    required
                    disabled={myContainer.sample === null}
                    setData={(data) => {
                        const uom = unitOfMeasure.find(
                            (x) => x.uoMName === data.requestedAmountUoM
                        );
                        setMyContainer({
                            ...data,
                            requestedAmount: null,
                            unitOfMeasure: uom,
                        });
                    }}
                />
                <Box display="flex" flexDirection="column" width={300} alignItems='center'>
                    <Typography>Original Amt.:</Typography>
                    <Typography>{getOriginalAmount(totalAmt, myContainer)}</Typography>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                <WorkRequestFormInput
                    {...commonProps}
                    label={labelProp['requestedAmount']}
                    property="requestedAmount"
                    validation={(value) => value > 0}
                    setData={(data) => {
                        var numberRegex = /^\d*\.?\d*$/;
                        if (
                            numberRegex.test(data.requestedAmount) ||
                            data.requestedAmount === ""
                        ) {
                            const totalAmtValue = parseFloat(totalAmt.split(" ")[0]);
                            const currentValue = data.requestedAmount ? parseFloat(data.requestedAmount) : null;
                            const totalAvailable = parseFloat(myContainer.requestedAmount ?? 0) + totalAmtValue
                            let value = null;
                            if (currentValue || currentValue === 0) {
                                value =
                                    currentValue > totalAvailable
                                        ? totalAvailable < 0 ? 0 :
                                            totalAvailable
                                        : data.requestedAmount;
                            }

                            setMyContainer({
                                ...data,
                                requestedAmount: value
                            });
                        }
                    }}
                    required
                    disabled={myContainer.sample === null}
                />
                <Box display="flex" flexDirection="column" width={300} alignItems='center'>
                    <Typography>Amt. Available:</Typography>
                    <Typography>{getConvertedCurrentAmount(myContainer, false, containersSameKey)}</Typography>
                </Box>
            </Box>
            <Box display="flex" width={'100%'}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox />}
                        checked={myContainer.isUsedInShipping}
                        onChange={(e) => {
                            setMyContainer({
                                ...myContainer,
                                isUsedInShipping: e.target.checked
                            });
                        }}
                        label={<>Using in Shipping?</>}
                    />
                </FormGroup>
            </Box>
            {
                <LocateContainer
                    open={locateContainerIsOpen && locateContainerMidas === myContainer?.sample?.sampleName}
                    setOpen={handleLocateContainerOpen}
                    incomingUoMObject={null}
                    midasNumber={myContainer?.sample?.sampleName}
                    setSelectedContainer={(value) => {
                        setMyContainer({
                            ...myContainer,
                            existingContainerID: value?.id
                        });

                        setErrors({
                            ...errors,
                            existingContainerID: !value?.id || value?.id === ''
                        });
                    }}
                    updateAllMethods={null}
                    methodInfo={null}
                    allowAllSites={true}
                    onlySearch={locateContainerOnlySearch}
                />
            }
        </form>
    </ModalTwoButtons>
}
export default CreateUpdateContainers
