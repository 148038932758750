import { Document, Page, Text, View } from "@react-pdf/renderer";
import { convertDateFormat, formatMidasNumber } from "../../../../global";
import { getConvertedCurrentAmount } from "../../shared";
import { styles } from "./PrintStylesheet";

export default function PrintSpecialAnalysis({
  workRequest,
  files,
  methods,
  billingList,
  dotHazardClass,
}) {


  const jadeDescription =
    billingList.find((f) => f.costCode === workRequest.chargeCode)
      ?.description ?? "";

  const mountComments = (comments) => {
    let comment = "";
    comments.forEach((item) => {
      comment += item + "\n";
    });
    return comment;
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.title}>Request Details - {workRequest.id}</Text>
          <View>
            <Text style={styles.subtitle}>Work Request Common Info</Text>
            <View style={styles.tableDivider} />

            <View style={styles.row1}>
              <View style={styles.item50}>
                <Text style={styles.textHeader}>Requester</Text>
                <Text style={styles.text}>{workRequest.requestedByEmail}</Text>
              </View>
              <View style={styles.item50}>
                <Text style={styles.textHeader}>Assigned to</Text>
                <Text style={styles.text}>{workRequest.assignedToEmail}</Text>
              </View>
            </View>
            <View style={styles.row1}>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Category</Text>
                <Text style={styles.text}>{workRequest.typeName}</Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Facility</Text>
                <Text style={styles.text}>{workRequest.facilityName}</Text>
              </View>

              <View style={styles.item25}>
                <Text style={styles.textHeader}>Status</Text>
                <Text style={styles.text}>{workRequest.statusName}</Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Business Unit</Text>
                <Text style={styles.text}>{workRequest.businessUnitName}</Text>
              </View>
            </View>
            <View style={styles.row1}>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Jade Number</Text>
                <Text
                  style={styles.text}
                >{`${workRequest.chargeCode} ~ ${jadeDescription}`}</Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Created Date</Text>
                <Text style={styles.text}>
                  {convertDateFormat(workRequest.createdDate)}
                </Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Last Modified Date</Text>
                <Text style={styles.text}>
                  {convertDateFormat(workRequest.lastModifiedDate)}
                </Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}></Text>
                <Text style={styles.text}></Text>
              </View>
            </View>
            <View style={styles.row1}>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>SMG Priority</Text>
                <Text style={styles.text}>{workRequest.priorityName}</Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Request Completition Date</Text>
                <Text style={styles.text}>
                  {convertDateFormat(workRequest.requestedCompletionDate)}
                </Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Last Acceptable Date</Text>
                <Text style={styles.text}>
                  {convertDateFormat(workRequest.lastAcceptableCompletionDate)}
                </Text>
              </View>
              <View style={styles.item25}>
                <Text style={styles.textHeader}>Date Completed</Text>
                <Text style={styles.text}>
                  {convertDateFormat(workRequest.completedDate)}
                </Text>
              </View>
            </View>
            <View style={styles.row1}>
              <View style={styles.item100}>
                <Text style={styles.textHeader}>SMG Priority Reason</Text>
                <Text style={styles.text}>{workRequest.priorityReason}</Text>
              </View>
            </View>

            <View wrap={false} style={styles.row1}>
              <View style={styles.item100}>
                <Text style={styles.textHeader}>Request Description</Text>
                <Text style={styles.text}>{workRequest.description}</Text>
              </View>
            </View>
            <View wrap={false} style={styles.row1}>
              <View style={styles.item100}>
                <Text style={styles.textHeader}>Comments</Text>
                <Text style={styles.text}>
                  {workRequest.comments
                    ? mountComments(workRequest.comments.split("|"))
                    : ""}
                </Text>
              </View>
            </View>
            <Text style={styles.subtitle}>Sample Info</Text>
            <View style={styles.tableDivider} />
            <View wrap={false} style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableText, styles.item10]}>MIDAS #</Text>
                <Text style={[styles.tableText, styles.item10]}>
                  Container #
                </Text>
                <Text style={[styles.tableText, styles.item30]}>
                  Description
                </Text>
                <Text style={[styles.tableText, styles.item10]}>PSIMS ID</Text>
                <Text style={[styles.tableText, styles.item10]}>
                  Split Size
                </Text>
                <Text style={[styles.tableText, styles.item10]}>UoM</Text>
                <Text style={[styles.tableText, styles.item10]}>
                  Amt. Available
                </Text>
                <Text style={[styles.tableText, styles.item10]}>
                  Final Location
                </Text>
              </View>
              {workRequest.specialAnalysisSamples.map((x) => (
                <View style={styles.tableRow}>
                  <Text style={[styles.tableText, styles.item10]}>
                    {formatMidasNumber(x.sample?.sampleName)}
                  </Text>
                  <Text style={[styles.tableText, styles.item10]}>
                    {x.existingContainer?.containerNumber ?? "-"}
                  </Text>
                  <Text style={[styles.tableText, styles.item30]}>
                    {x.sample?.description}
                  </Text>
                  <Text style={[styles.tableText, styles.item10]}>
                    {x.sample?.psimsid}
                  </Text>
                  <Text style={[styles.tableText, styles.item10]}>
                    {x.requestedAmount}
                  </Text>
                  <Text style={[styles.tableText, styles.item10]}>
                    {x.requestedAmountUoM}
                  </Text>
                  <Text style={[styles.tableText, styles.item10]}>
                    {getConvertedCurrentAmount(x, true)}
                  </Text>
                  <Text style={[styles.tableText, styles.item10]}>
                    {x.requestedLocationName}{" "}
                    {x.deliveryLocationSublocation
                      ? ` | ${x.deliveryLocationSublocation}`
                      : ""}
                  </Text>
                </View>
              ))}
            </View>
            {!!methods?.length && (
              <>
                <Text style={styles.subtitle}>Test Methods Info</Text>
                <View style={styles.tableDivider} />
                <View wrap={false} style={styles.table}>
                  <View style={styles.tableRow}>
                    <Text style={[styles.tableText, styles.item10]}>
                      Method
                    </Text>
                    <Text style={[styles.tableText, styles.item30]}>
                      Method Description
                    </Text>
                    <Text style={[styles.tableText, styles.item10]}>
                      Facility
                    </Text>
                    <Text style={[styles.tableText, styles.item10]}>
                      Condition Offering
                    </Text>
                    <Text style={[styles.tableText, styles.item10]}>
                      Estimate
                    </Text>
                    <Text style={[styles.tableText, styles.item10]}>
                      Priority
                    </Text>
                  </View>
                  {methods.map((x) => (
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableText, styles.item10]}>
                        {x.method.name}
                      </Text>
                      <Text style={[styles.tableText, styles.item30]}>
                        {x.method ? x.method.description : ""}
                      </Text>
                      <Text style={[styles.tableText, styles.item10]}>
                        {x.methodFacility.testFacilityAbv}
                      </Text>
                      <Text style={[styles.tableText, styles.item10]}>
                        {x.conditionOffering?.conditionOfferingName ?? ""}
                      </Text>
                      <Text style={[styles.tableText, styles.item10]}>
                        {x.estimate ? x.estimate : ""}
                      </Text>
                      <Text style={[styles.tableText, styles.item10]}>
                        {x.testPriority.testPriorityName}
                      </Text>
                    </View>
                  ))}
                </View>
              </>
            )}

            <View>
              <Text style={styles.subtitle}>Attachments Link</Text>
              <View style={styles.tableDivider} />
              <View style={styles.item100}>
                {files.map((x) => (
                  <Text>{x.file.name}</Text>
                ))}
              </View>
            </View>

            {
              <View>
                <Text style={styles.subtitle}>Analysis Info</Text>
                <View style={styles.tableDivider} />
                <View style={styles.row1}>
                  <View styles={styles.item100}>
                    <Text style={styles.textHeader}>Data Classification</Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.dataClassificationName}
                    </Text>
                  </View>
                </View>
                <View style={styles.row1}>
                  <View style={styles.item100}>
                    <Text style={styles.textHeader}>
                      Description of material to be analysed: Origin, history,
                      previous analyses, correspondences, and all other related
                      background information. Attachments are welcome and
                      strongly encouraged to enhance out ability to most
                      expeditiously and most appropriately meet your needs:
                    </Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.requestDescription}
                    </Text>
                  </View>
                </View>
                <View style={styles.row1}>
                  <View style={styles.item100}>
                    <Text style={styles.textHeader}>
                      Fundamental reasons for analysis:
                    </Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.requestReason}
                    </Text>
                  </View>
                </View>
                <View style={styles.row1}>
                  <View style={styles.item100}>
                    <Text style={styles.textHeader}>
                      Analises required, if known, or nature of results
                      expected:
                    </Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.testingRequirements}
                    </Text>
                  </View>
                </View>

                {/* <View style={styles.row1}>
                  <View style={styles.item100}>
                    <Text style={styles.textHeader}>
                      Final report format (initial results will be reported by
                      phone or e-mail)
                    </Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.reportFormatName}
                    </Text>
                  </View>
                </View>
                <Text style={styles.subtitle}>
                  Test Info (For Analytical Use Only)
                </Text>
                <View style={styles.tableDivider} />
                <View style={styles.row1}>
                  <View style={styles.item50}>
                    <Text style={styles.textHeader}>Estimated Man/Hour</Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.estimatedTotalHours}
                    </Text>
                  </View>
                  <View style={styles.item50}>
                    <Text style={styles.textHeader}>
                      Estimated Completition Date
                    </Text>
                    <Text style={styles.text}>
                      {workRequest.specialRequest?.estimatedCompletionDate}
                    </Text>
                  </View>
                </View> */}
              </View>
            }
          </View>
        </View>
      </Page>
    </Document>
  );
}
