import { useContext, useEffect, useState } from "react";
import "./styles.css";
import { TextField, TableRow, RadioGroup, Radio, FormControlLabel, FormControl, IconButton, Autocomplete, CircularProgress } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell, UXDataTableNested } from "../../../../components/UXDataTable";
import { formatMidasNumber, RoundNumber, globalMessages, GetRequiredSampleSize, GetRequiredSampleSizeUoM, BuildTestingPayload, ConvertUOMs } from "../../../../global";
import { styled } from "@mui/material/styles";
import HelpIcon from '@mui/icons-material/Help';
import ModalMessages from "../../../../components/Modal/ModalSimpleButton";
import ContainerTable from "../../../../components/ContainerTable";
import ModalNoButton from "../../../../components/Modal/ModalNoButton";
import Container from "../../../../api/LIMS/Container";
import UserContext from "../../../../context/UserContext";
import WorkRequest from "../../../../api/WorkRequest/WorkRequest";
import { GlobalButton } from "../../../styles";

const midasColHeaders = ['MIDAS Number', 'Req. Size', 'Parent Container', 'Container Amt.', 'Sample Description']
const midasColHeaders_Existing = ['MIDAS Number', 'Options', 'Sample Description']
const existingColHeaders = ['Method', 'Container Options', 'Container', 'Req. Size', 'Container Amt.']

const StyledP = styled('p')({
    color: "#000000DE",
    fontFamily: "EMprint",
    fontWeight: "600",
    fontSize: "16px",
    paddingTop: "1rem",
    paddingLeft: ".6rem",
    paddingBottom: "1rem"
})

const WorkRequestContainerManagement = ({
    mySamplesInfo, setMySamplesInfo, availableLocations, methods, setMethods, selectedMyContainer, selectedSampleInfo, availableUOMs,
    containeringMethod, setContaineringMethod, prepareData, myWorkRequest,
}) => {
    const [sampleRowOpen, setSampleRowOpen] = useState([])

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok';
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [triggerContainerReload, setTriggerContainerReload] = useState(false);
    const [containerErrorChecks, setContainerErrorChecks] = useState(PopulateErrorInfo());

    const [modalNoButtonOpen, setModalNoButtonOpen] = useState(false);
    const [modalNoButtonTitle, setModalNoButtonTitle] = useState('');
    const [modalNoButtonText, setModalNoButtonText] = useState('');

    const [minSampleSize, setMinSampleSize] = useState(0);
    const [masterUoM, setMasterUoM] = useState();
    const [loading, setLoading] = useState();

    const currentUser = useContext(UserContext);

    function PopulateErrorInfo() {
        var containerErrors = []
        let i = 0

        while (i < mySamplesInfo.length) {
            containerErrors.push({ containerErrorInfo: [] })
            i++;
        }

        return containerErrors
    }

    useEffect(() => {
        if (availableUOMs.length === 0 || !methods || methods.length < 1)
            return

        let tempMasterUoM = availableUOMs.find(e => e.uoMName === GetRequiredSampleSizeUoM(methods[0]))
        let currentUOM
        let tempSampleSize = 0

        methods.forEach(oMethod => {
            currentUOM = availableUOMs.find(e => e.uoMName === GetRequiredSampleSizeUoM(oMethod))
            tempSampleSize = tempSampleSize + ConvertUOMs(null, null, GetRequiredSampleSize(oMethod), currentUOM, tempMasterUoM)
        });

        setMinSampleSize(RoundNumber(tempSampleSize, 2))
        setMasterUoM(tempMasterUoM)

    }, [methods, availableUOMs])

    function updateContainerErrorChecks(oErrors) {
        const copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks))

        copyContainerErrors[0].containerErrorInfo = oErrors
        setContainerErrorChecks(copyContainerErrors)
    }

    useEffect(() => {
        if (triggerContainerReload) {
            (async () => {
                await LoadContainers();
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerContainerReload])

    useEffect(() => {
        if (containeringMethod) {
            (async () => {
                await LoadContainers(true);
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods])

    const hasError = containerErrorChecks.some((containerError) => 
            containerError.containerErrorInfo.some((container) => Object.values(container).includes(true) )
        ) ?? false

    async function LoadContainers(ignoreTrigger = false) {
        let cancelPromise = false
        let newTests = []

        if (triggerContainerReload === false && !ignoreTrigger) {
            return
        }

        if (methods.some(s => !s.methodFacility)) {
            if (!ignoreTrigger) openModalMessages("Error!","The method and facility fields are required")
            return
        }

        if (containeringMethod === "existing") {
            if (methods.some((result) => result.createFromContainer === null) || methods.some((result) => result.containerSelectionType === null)) {
                let copyMyContainer = JSON.parse(JSON.stringify(selectedMyContainer));
                copyMyContainer.newContainers = [];

                updateMySamplesInfo(copyMyContainer);

                const copyContainerErrors = JSON.parse(
                    JSON.stringify(containerErrorChecks)
                );
                copyContainerErrors[0].containerErrorInfo = [];
                setContainerErrorChecks(copyContainerErrors);

                setTriggerContainerReload(false);
                return;
            }
        } else {
            if (methods.some((result) => result.createFromContainer === null)) {
                let copyMyContainer = JSON.parse(JSON.stringify(selectedMyContainer));
                copyMyContainer.newContainers = [];

                updateMySamplesInfo(copyMyContainer);

                const copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks));
                copyContainerErrors[0].containerErrorInfo = [];
                setContainerErrorChecks(copyContainerErrors);

                setTriggerContainerReload(false);
                return;
            }
        }

        for (const oMethodInfo of methods) {
            let myContainerID = null

            if (oMethodInfo.containerSelectionType === "Existing Container") {
                myContainerID = selectedMyContainer?.existingContainer.id;
            }

            const tests = await BuildTestingPayload(oMethodInfo, selectedMyContainer?.existingContainer, myContainerID, selectedMyContainer?.existingContainer?.chargeCode ? selectedMyContainer?.existingContainer?.chargeCode : null, currentUser.username)

            if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string') {
                if (Array.isArray(tests)) {
                    openModalMessages("Error Building Tests", tests)
                    return
                } else {
                    openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)
                    return
                }
            }

            for (const oTest of tests) {
                newTests.push(oTest)
            }
        }

        var testingInfo = {
            tests: newTests,
            containeringMethod: containeringMethod,
            requireParentContainer: true
        }

        openModalNoButton("Generating Containers", "Please wait while containers are generated...")

        Container.GetContainersToCreate(JSON.stringify(testingInfo)).then((res) => {
            if (cancelPromise) return

            closeModalNoButton()

            let arrayContainers = []
            let arrayErrors = []
            const copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks))

            if (res && res.message === 'Success') {
                res.result.forEach(oContainer => {

                    if (oContainer.id === 0) {
                        oContainer.ownerEmail = selectedMyContainer?.existingContainer?.requesterEmail ?? currentUser.username;
                        oContainer.locationName = null

                        //these properties are added to keep track of the min size, in case the user changes volumes
                        oContainer._originalAmount = oContainer.currentAmount
                        oContainer._originalUoM = oContainer.uom
                    }

                    oContainer.returnLocationName = selectedMyContainer.returnLocation?.locationName
                    oContainer.returnLocation = oContainer.returnLocationName ? availableLocations.find(obj => obj.locationName === oContainer.returnLocationName) : null

                    oContainer.location = oContainer.locationName ? availableLocations.find(obj => obj.locationName === oContainer.locationName) : null

                    //set up the error checking on the containers          
                    arrayContainers.push(oContainer)

                    arrayErrors.push({
                        containerType: false,
                        size: false,
                        currentAmount: false,
                        uom: false,
                        location: oContainer.location === null ? null : false,
                        ownerEmail: oContainer.ownerEmail === null ? null : false
                    })
                });

            } else {
                openModalMessages("Error Loading Containers", `There was an error loading the testing/retain containers for the selected tests and containering method. ${res ? res.message : ''}.`)
            }

            let copyMyContainer = JSON.parse(JSON.stringify(selectedMyContainer));
            copyMyContainer.newContainers = arrayContainers;

            updateMySamplesInfo(copyMyContainer);

            copyContainerErrors[0].containerErrorInfo = arrayErrors
            setContainerErrorChecks(copyContainerErrors)
        });

        setTriggerContainerReload(false)
    }

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }

    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const updateMySamplesInfo = (myContainer) => {
        const keyEdit = `${selectedMyContainer?.sample?.sampleName}${selectedMyContainer.existingContainerID}`;
        const allMyContainers = [...selectedSampleInfo.containers.map(info => {
            if (`${selectedSampleInfo?.sample?.sampleName}${info.existingContainerID}` === keyEdit) return myContainer;
            return info;
        })];

        setMySamplesInfo(mySamplesInfo.map(sample => {
            let sampleToReturn = sample;
            if (sample?.sample?.sampleName === selectedSampleInfo?.sample?.sampleName) {
                sampleToReturn = {
                    ...sample,
                    containers: allMyContainers
                }
            }
            return sampleToReturn;
        }));
    }

    const UpdateContainerManagementType = (e) => {
        setContaineringMethod(e.target.value);
        setTimeout(() => {
            setTriggerContainerReload(true)
        }, 1000);
    };

    const handleSampleRowClick = (midasNumber) => {
        const sampleRowOpenIndex = sampleRowOpen.indexOf(midasNumber);
        let newSampleRowOpen = [];

        if (sampleRowOpenIndex === -1) {
            newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen, midasNumber);
        } else if (sampleRowOpenIndex === 0) {
            newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen.slice(1));
        } else if (sampleRowOpenIndex === sampleRowOpen.length - 1) {
            newSampleRowOpen = newSampleRowOpen.concat(sampleRowOpen.slice(0, -1));
        } else if (sampleRowOpenIndex > 0) {
            newSampleRowOpen = newSampleRowOpen.concat(
                sampleRowOpen.slice(0, sampleRowOpenIndex),
                sampleRowOpen.slice(sampleRowOpenIndex + 1)
            );
        }
        setSampleRowOpen(newSampleRowOpen);
    };

    const isSampleRowOpen = (midasNumber) => sampleRowOpen.indexOf(midasNumber) !== -1;

    const UpdateMethodContainering = (value, methodInfo, property, updateAllMethods) => {
        let copyMethods = JSON.parse(JSON.stringify(methods))

        if (updateAllMethods) {
            for (var i = 0; i < copyMethods.length; i++) {
                copyMethods[i].createFromContainer = value
            }
        } else {
            let methodIndex = copyMethods.findIndex(s => s.method.name === methodInfo.methodName && s.methodFacility.testFacilityAbv === methodInfo.facility && (s.conditionOffering ? s.conditionOffering.conditionOfferingName === methodInfo.conditionOfferingName : true))

            copyMethods[methodIndex][property] = value
        }

        setMethods(copyMethods);

        setTriggerContainerReload(true)
    }

    function UpdateContainerList(containers) {
        const newSelectedMyContainer = structuredClone(selectedMyContainer)

        newSelectedMyContainer.newContainers = containers

        updateMySamplesInfo(newSelectedMyContainer)
    }

    function closeModalNoButton() {
        setModalNoButtonOpen(false);
    }

    function openModalNoButton(title, text) {
        setModalNoButtonOpen(true);
        setModalNoButtonTitle(title);
        setModalNoButtonText(text);
    }

    const handleCreateContainers = async () => {
        setLoading(true);
        const [data] = await prepareData(myWorkRequest);
        WorkRequest.createContainers(data)
            .then((res) => {
                setLoading(false);

                if (res && res.result) {
                    let copyMyContainer = JSON.parse(JSON.stringify(selectedMyContainer));
                    let currentSample = res.result.workRequestSamples.find(
                        (x) => x.id === copyMyContainer.id
                    );
                    copyMyContainer.newContainer = currentSample.newContainer
                    copyMyContainer.newContainerId = currentSample.newContainerId
                    updateMySamplesInfo(copyMyContainer);
                    openModalMessages("Creating Containers ", `Created containers with success`);
                } else {
                    openModalMessages("Error Creating Containers", `There was an error creating containers. ${res.message ? res.message : ''}`)
                }
            })
    };

    return <div className="work-request-container-management">
        <p style={{
            paddingTop: "16px"
        }}></p>

        <FormControl>
            <RadioGroup
                rows="true"
                aria-labelledby="row-radio-buttons-group-label"
                name="container-radio-buttons-group"
            >
                <div style={{ display: "flex" }}>
                    <FormControlLabel value="nonsplit" control={<Radio checked={containeringMethod === 'nonsplit'} onChange={UpdateContainerManagementType} />} label="Make NonSplit" />
                    <HelpIcon style={{ marginTop: ".5rem", color: "blue" }} onClick={(e) => openModalMessages("Container Info", globalMessages.NonSplitMsg)}></HelpIcon>
                </div>

                <div style={{ display: "flex" }}>
                    <FormControlLabel value="midas" control={<Radio checked={containeringMethod === 'midas'} onChange={UpdateContainerManagementType} />} label="MIDAS Recommendation" />
                    <HelpIcon style={{ marginTop: ".5rem", color: "blue" }} onClick={(e) => openModalMessages("Container Info", globalMessages.MidasMsg)}></HelpIcon>
                </div>
                <div style={{ display: "flex" }}>
                    <FormControlLabel value="existing" control={<Radio checked={containeringMethod === 'existing'} onChange={UpdateContainerManagementType} />} label="Existing Container" />
                    <HelpIcon style={{ marginTop: ".5rem", color: "blue" }} onClick={(e) => openModalMessages("Container Info", globalMessages.existingMsg)}></HelpIcon>
                </div>
            </RadioGroup>
        </FormControl>

        <p style={{
            paddingTop: "16px"
        }}></p>

        {containeringMethod === 'existing' ?
            <UXDataTableWithoutBody
                tableWidth='60%'
                cols={midasColHeaders_Existing}
                blankFirstHeader={false}
                tableId="containerTable"
                rowLength={mySamplesInfo ? mySamplesInfo.length : 0}
                enablePaging={false}
                rowsPerPage={0}
                page={0}
                handleChangePage={null}
                handleChangeRowsPerPage={null}
                noDataFoundMessage={'No samples found'}
                enableAddIcon={false}
                addFunction={null}
                addToolTipText={null}
                isDataLoading={false}
            >
                <StyledTableBody key={"SamplesTable" + selectedMyContainer?.sample?.sampleName}>
                    <TableRow>
                        <StyledTableCell>{formatMidasNumber(selectedMyContainer?.sample?.sampleName)}</StyledTableCell>
                        <StyledTableCell>View
                            <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => handleSampleRowClick(selectedMyContainer?.sample?.sampleName)}>
                                {isSampleRowOpen(selectedMyContainer?.sample?.sampleName) ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>{selectedMyContainer?.sample?.description}</StyledTableCell>
                    </TableRow>

                    <UXDataTableNested
                        tableWidth={'100%'}
                        cols={existingColHeaders}
                        blankFirstHeader={false}
                        colSpan={midasColHeaders_Existing.length + 1}
                        isOpenComparator={selectedMyContainer?.sample?.sampleName}
                        isOpenArray={sampleRowOpen}>

                        <StyledTableBody>
                            {methods ? methods?.map((methodObj, methodIndex) => (

                                <TableRow key={selectedMyContainer?.sample?.sampleName + methodIndex}>
                                    <StyledTableCell>{methodObj.method.name}</StyledTableCell>

                                    <StyledTableCell align="left" style={{ verticalAlign: 'top' }}>
                                        <Autocomplete
                                            disablePortal
                                            options={["Create From Parent", "Existing Container"]}
                                            onChange={(e, value) => UpdateMethodContainering(value, { methodName: methodObj.method.name, facility: methodObj.methodFacility.testFacilityAbv, conditionOfferingName: methodObj.conditionOffering?.conditionOfferingName }, 'containerSelectionType', false)}
                                            getOptionLabel={(option) => option}
                                            value={methodObj.containerSelectionType}
                                            isOptionEqualToValue={(option, value) => value === option}
                                            style={{ fontFamily: "EMprint", fontSize: "16px", verticalAlign: 'top' }}
                                            renderInput={(params) => <TextField {...params} style={{ width: "230px" }} variant="outlined" size="small" error={methodObj.containerSelectionError} InputProps={{ ...params.InputProps }} />} />
                                    </StyledTableCell>

                                    {/* Locate Container */}
                                    {/* <StyledTableCell align='center' style={{ width: "150px" }}>
                                        <ManageSearchIcon
                                            onClick={() => PrepareToLocateContainers(true, selectedMyContainer?.sample?.sampleName, false, { methodName: methodObj.method.name, facility: methodObj.methodFacility.testFacilityAbv })}
                                        >
                                        </ManageSearchIcon>
                                    </StyledTableCell> */}

                                    <StyledTableCell align="left">
                                        {selectedMyContainer?.existingContainer?.containerNumber}
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        {RoundNumber(GetRequiredSampleSize(methodObj), 2)} {GetRequiredSampleSizeUoM(methodObj)}
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        {methodObj.createFromContainer ? methodObj.createFromContainer.currentAmount : 'N/A'} {methodObj.createFromContainer ? methodObj.createFromContainer.containerSizeUoM : ''}
                                    </StyledTableCell>

                                </TableRow>

                            )) : (<TableRow>
                                <StyledTableCell colSpan='8'>
                                    <p>No Methods Found</p>
                                </StyledTableCell>
                            </TableRow>)}
                        </StyledTableBody>
                    </UXDataTableNested>
                </StyledTableBody>

            </UXDataTableWithoutBody>
            : containeringMethod === 'nonsplit' || containeringMethod === 'midas' ?
                <UXDataTableWithoutBody
                    tableWidth='70%'
                    cols={midasColHeaders}
                    blankFirstHeader={false}
                    tableId="containerTableNew"
                    rowLength={mySamplesInfo ? 1 : 0}
                    enablePaging={false}
                    rowsPerPage={0}
                    page={0}
                    handleChangePage={null}
                    handleChangeRowsPerPage={null}
                    noDataFoundMessage={'No containers found'}
                    enableAddIcon={false}
                    addFunction={null}
                    addToolTipText={null}
                    isDataLoading={false}
                >

                    <StyledTableBody key={"myTablebody"}>
                        <TableRow
                            hover
                            key={`custom-row-${selectedMyContainer?.sample?.sampleName}`}>

                            <StyledTableCell>{formatMidasNumber(selectedMyContainer?.sample?.sampleName)}</StyledTableCell>

                            {/* Locate Container */}
                            {/* <StyledTableCell align='center' style={{ width: "150px" }}>
                            <ManageSearchIcon
                                onClick={() => PrepareToLocateContainers(true, selectedMyContainer?.sample?.sampleName, true, null)}
                            >
                            </ManageSearchIcon>
                        </StyledTableCell> */}

                            <StyledTableCell>
                                {`${minSampleSize} ${masterUoM ? masterUoM.uoMName : '???'}`}
                            </StyledTableCell>

                            <StyledTableCell>
                                {selectedMyContainer?.existingContainer?.containerNumber}
                            </StyledTableCell>
                            <StyledTableCell>{methods[0]?.createFromContainer ? `${methods[0]?.createFromContainer?.currentAmount} ${methods[0].createFromContainer?.containerSizeUoM}` : ''}</StyledTableCell>

                            {/* <StyledTableCell>{selectedMyContainer ? `${selectedMyContainer?.existingContainer?.currentAmount} ${selectedMyContainer?.existingContainer?.containerSizeUoM}` : ''}</StyledTableCell> */}
                            <StyledTableCell>{selectedMyContainer?.sample?.description}</StyledTableCell>

                        </TableRow>
                    </StyledTableBody>
                </UXDataTableWithoutBody>
                : <></>
        }

        {/* <LocateContainer
            open={modalLocateContainerOpen}
            setOpen={setModalLocateContainerOpen}
            incomingUoMObject={masterUoM}
            midasNumber={sampleNameToLocateContainers}
            setSelectedContainer={UpdateMethodContainering}
            updateAllMethods={updateAllMethods}
            methodInfo={methodToUpdate}
        /> */}

        {containeringMethod && containeringMethod !== '' && <StyledP>Containers to Create</StyledP>}

        {containeringMethod && containeringMethod !== '' && containerErrorChecks[0]?.containerErrorInfo && <div style={{ paddingBottom: "2rem" }}>
            <ContainerTable
                width={'95%'}
                showParentContainer={false}
                showTests={true}
                containers={selectedMyContainer?.newContainers ?? []}
                setContainers={UpdateContainerList}
                containerErrorChecks={containerErrorChecks[0].containerErrorInfo}
                setContainerErrorChecks={updateContainerErrorChecks}
                enabledAdditions={false}
                enableDeletions={false}
                enableCopy={false}
                availableParentContainers={null}
                sample={selectedMyContainer?.sample}
                substanceObject={selectedMyContainer?.sample?.substance ? selectedMyContainer?.sample?.substance : null}
                lockContainerStatus={true}
                newContainerDefaultOwner={null}
            >
            </ContainerTable>
        </div>}

        {containeringMethod && containeringMethod !== '' && containerErrorChecks[0]?.containerErrorInfo && <GlobalButton
            disabled={
                // !isTechnicianRole ||
                hasError ||
                selectedMyContainer.newContainer?.containerNumber > 0 ||
                loading ||
                methods.some(s => !s.methodFacility)
            }
            variant="contained"
            onClick={handleCreateContainers}
        >
            Create Containers
            {loading ? (
                <CircularProgress
                    sx={{ marginLeft: "10px", color: "white" }}
                    size={20}
                />
            ) : (
                ""
            )}
        </GlobalButton>}

        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
            <label>
                {modalMessagesText}
            </label>
        </ModalMessages>

        <ModalNoButton title={modalNoButtonTitle} open={modalNoButtonOpen} setOpen={setModalNoButtonOpen}>
            <label>
                {modalNoButtonText}
            </label>
        </ModalNoButton>
    </div>
}
export default WorkRequestContainerManagement
