import { useEffect, useState } from "react";
import SpecialAnalysis from "../../../api/SpecialAnalysis/SpecialAnalysis";
import SpecialAnalysisFileAttachment from "../../../api/SpecialAnalysis/SpecialAnalysisFileAttachment";
import ViewSpecialAnalysisForm from "./ViewSpecialAnalysisForm";
import BillingInfo from "../../../api/Admin/BillingInfo";
import { useLocation } from 'react-router-dom';
import DOTHazardClass from "../../../api/WorkRequest/DOTHazardClass";

export default function ViewSpecialAnalysis({ formRequestDetailsRef, match }) {
  const workRequestId = match?.params?.id;
  const [myWorkRequest, setMyWorkRequest] = useState();
  const [myWorkRequestStatic, setMyWorkRequestStatic] = useState();
  const [initialStatus, setInitialStatus] = useState('');
  const [files, setFiles] = useState([]);
  const [billingList, setBillingList] = useState([]);
  const [dotHazardClass, setDOTHazardClass] = useState([]);
  const [dotHazardClassLoading, setDOTHazardClassLoading] = useState(true);

  const location = useLocation()
  const fromTab = location.state && location.state.tab ? location.state.tab : null

  useEffect(() => {
    SpecialAnalysis.getById(workRequestId).then((result) => {
      if (result) {
        result.typeName = result.typeName === "Fuels Request w/ Shipping" ? "Shipping" : result.typeName
        setMyWorkRequest(result);
        setMyWorkRequestStatic(JSON.parse(JSON.stringify(result)));

        setInitialStatus(result.statusName);
      }
    });
    SpecialAnalysisFileAttachment.getAll(workRequestId).then((res) => {
      setFiles(
        res.map((file) => {
          return {
            file: {
              ...file,
              name: file.fileName,
            },
          };
        })
      );
    });

    BillingInfo.getAll().then((res) => {
      if (res.message === 'Success') {
        setBillingList(res.result.filter(result => result.isActive === true))
      }
    });

    DOTHazardClass.getAllActive().then((res) => {
      setDOTHazardClassLoading(false);
      setDOTHazardClass(
        res.sort((a, b) => a.displayName.localeCompare(b.displayName))
      );
    })
  }, [workRequestId]);

  return (
    <div>
      {myWorkRequest && billingList && !dotHazardClassLoading && (
        <ViewSpecialAnalysisForm
          billingList={billingList}
          myWorkRequest={myWorkRequest}
          myWorkRequestStatic={myWorkRequestStatic}
          setMyWorkRequest={setMyWorkRequest}
          files={files}
          setFiles={setFiles}
          fromTab={fromTab}
          dotHazardClass={dotHazardClass ?? []}
          dotHazardClassLoading={dotHazardClassLoading}
          initialStatus={initialStatus}
        />
      )}
    </div>
  );
}
