import { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../../../components/UXDataTable";
import { Box, Chip, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GlobalButton } from "../../../styles";
import CustomModal from "../../../../components/Modal";
import { ProjectForm, ProjectFormCriteria } from "./ProjectForm";
import Project from "../../../../api/Admin/Project";
import { DataTableWrapper } from "./components/DataTableWrapper";
import { sortAscending } from "./helpers";
import { CustomNetworkError } from "../../../../api/helpers";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { Alert } from "./components/Alert";
import { CustomMenu } from "./components/CustomMenu";
import { ProjectResubmitForm } from "./ProjectResubmitForm"

type ProjectManagementAlert = {
  title: string;
  message: string;
  details?: string | undefined;
  onOk?: () => void;
};

const newProjectInitialValues = new Project({
  id: 0,
  name: "",
  description: "",
  ownerEmail: "",
  secondaryOwnerEmail: "",
  isActive: true,
  restrictAtEndOfLife: true,
  lastVerifiedDate: undefined,
  projectEndDate: undefined,
  ndaAgreementFileName: undefined,
  ndaAgreementFile: undefined,
  userCanManageProject: false,
  reviewStatus: "",
  rejectionMessage: ""
});

const ProjectManagement = () => {
  const [showProjectFormModal, setShowProjectFormModal] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [processLoading, setProcessLoading] = useState<boolean>(false);
  const [userIsUnauthorized, setUserIsUnauthoirzed] = useState<boolean>(true);
  const [alert, setAlert] = useState<ProjectManagementAlert | undefined>(undefined);
  const [showProjectResubmitFormModal, setShowProjectResubmitFormModal] = useState(false);
  const [triggerEffect, setTriggerEffect] = useState(0);

  const tableKey = "projectsTable";
  const columns = [
    {
      id: "name",
      label: "Project Name",
      notSorteable: false,
    },
    {
      id: "description",
      label: "Description",
      notSorteable: false,
    },
    {
      id: "ownerEmail",
      label: "Project Owner",
      notSorteable: false,
    },
    {
      id: "secondaryOwnerEmail",
      label: "Secondary Owner",
      notSorteable: false,
    },
    {
      id: "isActive",
      label: "Status",
      notSorteable: false,
    },
    {
      id: "reviewStatus",
      label: "Review Status",
      notSorteable: false,
    },
  ];

  const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403) {
      return "You are not authorized to view or modify projects.";
    }
    return (
      error.message ??
      "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
  };  

  useEffect(() => {
    setLoading(true);
    Project.getAllManagementProjects(["Approved","Rejected"])
      .then((response: Project[]) => {
        setUserIsUnauthoirzed(false);
        setProjects(response.sort((a, b) => sortAscending(a.name, b.name)));
      })
      .catch((err) => {
        setAlert(errorFetchingProjectsAlert(getNetworkErrorMessage(err)));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [triggerEffect]);

  const createFilePlaceholder = (fileName: string) => {
    const filePlaceholder = new File([], fileName);
    return filePlaceholder;
  };

  const handleEditClick = (project: Project) => {
    if (project.ndaAgreementFileName && project.ndaAgreementFileName.length > 0) {
      project.ndaAgreementFile = createFilePlaceholder(project.ndaAgreementFileName);
    }
    setProject(project);
    setShowProjectFormModal(true);
    getProject(project.id);
  };

  const handleResubmitClick = (project: Project) => {    
    if(project.reviewStatus === "Rejected"){
      setProject(project);
      setShowProjectResubmitFormModal(true);
      getProject(project.id);
    }
  };

  const handleNewProjectClick = () => {
    setShowProjectFormModal(true);
  };

  const getProject = (id: number) => {
    setProcessLoading(true);
    Project.get(id)
      .then((response: Project) => {
        if (response.ndaAgreementFileName && response.ndaAgreementFileName.length > 0) {
          response.ndaAgreementFile = createFilePlaceholder(response.ndaAgreementFileName);
        }
        setProject(response);
        setProcessLoading(false);
      })
      .catch((err) => {
        setAlert(errorFetchingProjectAlert(getNetworkErrorMessage(err)));
        setShowProjectFormModal(false);
      })
      .finally(() => {
        setProcessLoading(false);
      });
  };

  const createProject = (request: Project) => {
    Project.create(request)
      .then((response: Project) => {
        setProjects((prevProjects) => {
          return prevProjects.concat([response]);
        });

        setProcessLoading(false);
        handleProjectFormOnClose();
        setAlert(successfullyCreatedProjectAlert);
      })
      .catch((err) => {
        setAlert(errorCreatingProjectAlert(getNetworkErrorMessage(err)));
      })
      .finally(() => {
        setProcessLoading(false);
      });
  };

  const updateProject = (request: Project) => {
    Project.update(request.id, request)
      .then((response: Project) => {
        const indexToUpdate = projects.findIndex((project) => project.id === request.id);

        setProjects((prevProjects) => {
          prevProjects[indexToUpdate] = response;
          return prevProjects;
        });

        setProcessLoading(false);
        handleProjectFormOnClose();
        setAlert(successfullyUpdatedProjectAlert);
      })
      .catch((err) => {
        setAlert(errorUpdatingProjectAlert(getNetworkErrorMessage(err)));
      })
      .finally(() => {
        setProcessLoading(false);
      });
  };

  const handleProjectFormOnSave = (formValues: ProjectFormCriteria) => {
    setProcessLoading(true);

    const projectToUpdate = projects.find((project) => project.id === formValues.id);

    if (projectToUpdate) {
      updateProject({
        ...projectToUpdate,
        projectEndDate: formValues?.expirationDate,
        ndaAgreementFileName: formValues?.ndaAgreementFile?.name,
        ...formValues,
      });
    } else {
      createProject({
        ...newProjectInitialValues,
        projectEndDate: formValues?.expirationDate,
        ndaAgreementFileName: formValues?.ndaAgreementFile?.name,
        ...formValues,
      });
    }
  };

  const handleProjectFormOnClose = () => {
    setProject(undefined);
    setShowProjectFormModal(false);
  };

  const handleProjectResubmitFormOnClose = () => {
    setProject(undefined);
    setShowProjectResubmitFormModal(false);
    setTriggerEffect((prev) => prev + 1);
  };

  const handleAlertOkClick = () => {
    alert?.onOk?.();
    setAlert(undefined);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingBottom={".8rem"}>
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <Header>All Projects ({projects.length})</Header>
          <GlobalButton
            variant="contained"
            onClick={() => handleNewProjectClick()}
            disabled={userIsUnauthorized}>
            New Project
          </GlobalButton>
        </Box>
      </Box>
      <DataTableWrapper
        tableId="projects"
        columns={columns}
        tableWidth="100%"
        blankFirstHeader={true}
        noDataFoundMessage={
          userIsUnauthorized
            ? "You are not authorized to view or modify projects."
            : "No projects available."
        }
        isDataLoading={loading}
        rowData={projects}
        rowComponent={(project, rowIndex) => (
          <StyledTableRow key={`${tableKey}-row-${rowIndex}`}>
            <StyledTableCell width={5}>
              <CustomMenu key={`${tableKey}basic-menu${rowIndex}`}>
                <MenuItem onClick={() => handleEditClick(project)}>Edit</MenuItem>
              </CustomMenu>
            </StyledTableCell>
            <StyledTableCell>{project.name}</StyledTableCell>
            <StyledTableCell>{project.description}</StyledTableCell>
            <StyledTableCell>{project.ownerEmail}</StyledTableCell>
            <StyledTableCell>{project.secondaryOwnerEmail}</StyledTableCell>
            <StyledTableCell>
              <Chip
                label={project.isActive ? "Active" : "Inactive"}
                color={project.isActive ? "success" : undefined}
                disabled={!project.isActive}
              />
            </StyledTableCell>
            <StyledTableCell>              
                <Chip
                  label={project.reviewStatus}
                  color={project.reviewStatus === "Approved" ? "success" : "error"}
                  onClick={() => handleResubmitClick(project)}               
                /> 
              
            </StyledTableCell>                
          </StyledTableRow>
        )}
      />
      <CustomModal
        title={`${project ? "Edit" : "Add"} New Project`}
        open={showProjectFormModal}
        setOpen={setShowProjectFormModal}
        setClose={undefined}>
        <ProjectForm
          project={project}
          invalidProjectNames={projects.compactMap((p) => (p.id === project?.id ? null : p.name))}
          disabled={processLoading}
          onSave={handleProjectFormOnSave}
          onCancel={handleProjectFormOnClose}
        />
      </CustomModal>
      <CustomModal
        title="Resubmit Project For Review"
        open={showProjectResubmitFormModal}
        setOpen={setShowProjectResubmitFormModal}
        setClose={undefined}>
        <ProjectResubmitForm
          project={project}
          onCancel={handleProjectResubmitFormOnClose}        
        />
      </CustomModal>      
      <Alert
        title={alert?.title}
        message={alert?.message}
        details={alert?.details}
        display={alert !== undefined}
        onOk={handleAlertOkClick}
      />
      <ProgressSpinner display={processLoading} />
    </>
  );
};

const errorFetchingProjectsAlert = (details: string): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error fetching projects.",
  details: `Reason: ${details}`,
});

const errorFetchingProjectAlert = (details: string): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error fetching project.",
  details: `Reason: ${details}`,
});

const errorUpdatingProjectAlert = (details: string): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error updating the project.",
  details: `Reason: ${details}`,
});

const errorCreatingProjectAlert = (details: string): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error creating a new project.",
  details: `Reason: ${details}`,
});

const successfullyCreatedProjectAlert: ProjectManagementAlert = {
  title: "Success",
  message: "Project was successfully created.",
};

const successfullyUpdatedProjectAlert: ProjectManagementAlert = {
  title: "Success",
  message: "Project was successfully updated.",
};

const Header = styled(Box)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  paddingRight: 10,
}));

export default ProjectManagement;
