import API from "..";
import { checkAPIResponse } from "../helpers";

export default class Project {
  constructor({
    id,
    name,
    description,
    ownerEmail,
    secondaryOwnerEmail,
    isActive,
    restrictAtEndOfLife,
    lastVerifiedDate,
    projectEndDate,
    ndaAgreementFileName,
    ndaAgreementFile,
    userCanManageProject,
    reviewStatus,
    rejectionMessage
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.ownerEmail = ownerEmail;
    this.secondaryOwnerEmail = secondaryOwnerEmail;
    this.lastVerifiedDate = lastVerifiedDate;
    this.projectEndDate = projectEndDate;
    this.isActive = isActive;
    this.restrictAtEndOfLife = restrictAtEndOfLife;
    this.ndaAgreementFileName = ndaAgreementFileName;
    this.ndaAgreementFile = ndaAgreementFile;
    this.userCanManageProject = userCanManageProject;
    this.reviewStatus = reviewStatus;
    this.rejectionMessage = rejectionMessage;
  }

  static async getAll() {
    const api = await API();
    const response = await api.get(`/project`);
    checkAPIResponse(response);
    return response.data.result.map((d) => new Project(d));
  }

  static async get(id) {
    const api = await API();
    const response = await api.get(`/project/${id}`);
    checkAPIResponse(response);
    return response.data.result;
  }

  static async getByUser(userEmail) {
    const api = await API();
    const response = await api.get(`/project/byuser/${userEmail}`);
    checkAPIResponse(response);
    return response.data.result;
  }

  static async create(project) {
    const api = await API("multipart/form-data");
    const projectFormData = projectToFormData(project);
    const response = await api.post(`/project`, projectFormData);
    checkAPIResponse(response);
    return response.data.result;
  }

  static async update(projectId, project) {
    const api = await API("multipart/form-data");
    const projectFormData = projectToFormData(project);
    const response = await api.put(`/project/${projectId}`, projectFormData);
    checkAPIResponse(response);
    return response.data.result;
  }

  static async downloadNdaAgreementFile(id) {
    const api = await API();
    const response = await api.get(`/project/${id}/downloadFile`);
    checkAPIResponse(response);
    return response.data.result;
  }

  static async getAllManagementProjects(reviewStatusList) {
    const api = await API();
    const params = new URLSearchParams();
    reviewStatusList.forEach(reviewStatus => params.append('reviewStatusList', reviewStatus))
    const response = await api.get(`project/mgmt?${params.toString()}`);
    checkAPIResponse(response);
    return response.data.result.map((d) => new Project(d));
  }

  static async reviewPendingProjects(id, isApproved, rejectMessage) {
    const api = await API();
    const response = await api.put(`project/review?id=${id}&isApproved=${isApproved}&rejectMessage=${rejectMessage}`);
    checkAPIResponse(response);
    return response.data.result;
  }

  static async resubmitReviewProjects(id) {
    const api = await API();
    const response = await api.put(`project/resubmit?id=${id}`);
    checkAPIResponse(response);
    return response.data.result;
  }
}

const projectToFormData = (project) => {
  const formData = new FormData();
  project.id && formData.append("id", project.id);
  project.name && formData.append("name", project.name);
  project.description && formData.append("description", project.description);
  project.ownerEmail && formData.append("ownerEmail", project.ownerEmail);
  project.secondaryOwnerEmail &&
    formData.append("secondaryOwnerEmail", project.secondaryOwnerEmail);
  project.isActive && formData.append("isActive", project.isActive);
  project.restrictAtEndOfLife &&
    formData.append("restrictAtEndOfLife", project.restrictAtEndOfLife);
  project.lastVerifiedDate &&
    formData.append("lastVerifiedDate", new Date(project.lastVerifiedDate).toUTCString());
  project.restrictedAccess && formData.append("restrictedAccess", project.restrictedAccess);
  project.projectEndDate &&
    formData.append("projectEndDate", new Date(project.projectEndDate).toUTCString());
  project.ndaAgreementFileName &&
    formData.append("ndaAgreementFileName", project.ndaAgreementFileName);
  project.ndaAgreementFile && formData.append("ndaAgreementFile", project.ndaAgreementFile);
  formData.append("reviewStatus", project.reviewStatus);
  project.reviewStatus && formData.append("reviewStatus", project.reviewStatus);
  formData.append("rejectionMessage", project.rejectionMessage);
  project.rejectionMessage && formData.append("rejectionMessage", project.rejectionMessage);
  return formData;
};
