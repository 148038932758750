import API from "..";

export default class SDSRequestIntendedUse {

  constructor({
    intendedUseName,
    isActive
  }) {
    this.intendedUseName = intendedUseName;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestIntendedUse`);
    if (data.result) {
      return data.result.map((d) => new SDSRequestIntendedUse(d));
    }
    else {
      return [];
    }
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestIntendedUse`);
    if (data && data.result && data.result.length > 0) {
      return data.result.filter((d) => d.isActive);
    }
    else {
      return [];
    }
  }

  static async create(intendedUse) {
    const api = await API();
    const response = await api.post(`/sdsRequestIntendedUse`, intendedUse);
    if (response.status !== 200) {
      throw new Error(`Error: ${response.message}`);
    }
    return response.data;
  }

  static async update(intendedUse) {
    const api = await API();
    const response = await api.put(`/sdsRequestIntendedUse`, intendedUse);
    if (response.status !== 200) {
      throw new Error(`Error: ${response.message}`);
    }
    return response.data;
  }
}
